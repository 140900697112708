import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

import Stimulus from "../../common/utils/stimulus"

class FormHint extends Controller {
  static values = { placeholder: String }

  #tippy = null

  connect() {
    this.#tippy = tippy(this.element, { content: this.element.innerHTML })
    this.element.innerHTML = this.placeholderValue
    this.element.title = this.element.innerHTML
  }

  disconnect() {
    this.#tippy.destroy()
  }
}

Stimulus.register(`form-hint`, FormHint)
